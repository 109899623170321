<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                ITEM
            </h2>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="mt-2 mb-0">
                <v-row no-gutters>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-combobox
                            v-model="item.partNumber"
                            :items="partNumbers"
                            item-text="code"
                            prepend-icon="mdi-barcode"
                            label="Part number: *"
                            class="mr-xl-2 mr-lg-2 mr-md-2"
                            :rules="[rules.required]"
                            required
                            :disabled="!this.createForm"
                        >
                        </v-combobox>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="item.description"
                            prepend-icon="mdi-text"
                            label="Description: *"
                            :rules="[rules.required]"
                            required
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="item.mass"
                            type="number"
                            prepend-icon="mdi-weight"
                            label="Mass: (Kgs): *"
                            class="mr-xl-2 mr-lg-2 mr-md-2"
                            :rules="[rules.required, rules.number]"
                            required
                            @input="restrictMassDecimals"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="item.paintingArea"
                            type="number"
                            prepend-icon="mdi-arrow-expand-all"
                            label="Painting Area: (m2) *"
                            :rules="[rules.required, rules.number]"
                            required
                            @input="restrictPaintingAreaDecimals"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-combobox
                            v-model="item.finishAndUCCode"
                            :items="finishes"
                            item-text="name"
                            item-value="id"
                            prepend-icon="mdi-barcode"
                            label="Finish & UC Code: *"
                            class="mr-xl-2 mr-lg-2 mr-md-2"
                            :rules="[rules.required]"
                            required
                        >
                        </v-combobox>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="item.quantity"
                            type="number"
                            prepend-icon="mdi-database-outline"
                            label="Quantity: *"
                            :rules="[rules.required, rules.number]"
                            required
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="item.notes"
                            prepend-icon="mdi-text"
                            label="Notes: "
                            class="mr-xl-2 mr-lg-2 mr-md-2"
                            required
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-combobox
                            v-model="item.processes"
                            :items="processes"
                            item-text="name"
                            item-value="id"
                            multiple
                            prepend-icon="mdi-table-column"
                            label="Processes: *"
                            :rules="[rules.minItems, rules.required]"
                            required
                        >
                        </v-combobox>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                rounded
                color="primary"
                class="mb-5 mt-n4"
                @click="saveItem"
                :disabled="!valid"
                :loading="loading"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
import { objDiff } from '@/helpers/objDiff.js'

export default {
    name: 'ItemForm',
    props: {
        workOrder: {
            type: Object,
            default: () => ({}),
        },
        partNumbers: {
            type: Array,
            default: () => [],
        },
        item: {
            type: Object,
            default: () => ({}),
        },
        originalItem: {
            type: Object,
            default: () => ({}),
        },
        createForm: {
            type: Boolean,
            default: false,
        },
        settingProcesses: {
            type: Object,
            default: () => ({}),
        },
        settingFinish: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        valid: false,
        loading: false,
        finishes: [],
        processes: [],
        rules: {
            required: v => !!v || 'The value is required',
            number: v => v >= 0 || 'The value must be positive',
            minItems: v => v.length > 0 || 'Select at least one process',
        },
    }),
    computed: {
        itemDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalItem, this.item)
            } else {
                return null
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            if (this.settingProcesses.id) {
                this.processes = this.settingProcesses.processes
                if (this.item.processes?.length > 0) {
                    this.item.processes = this.processes.filter(process =>
                        this.item.processes.includes(process.id)
                    )
                }
            }
            if (this.settingFinish.id) {
                this.finishes = this.settingFinish.finishes
                if (this.item.finishAndUCCode) {
                    this.item.finishAndUCCode = this.finishes.find(
                        e => e.id == this.item.finishAndUCCode
                    )
                }
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async saveItem() {
            if (this.createForm) {
                await this.addNewItem()
            } else {
                await this.updateItem()
            }
        },
        async addNewItem() {
            try {
                this.loading = true
                delete this.item.material
                delete this.item.processesToShow
                delete this.item.productionInfo
                const {
                    partNumber,
                    finishAndUCCode,
                    processes,
                    mass,
                    paintingArea,
                    ...paramsToSave
                } = this.item
                const newItem = await API.createWorkOrderItem({
                    workOrderId: this.workOrder.id,
                    partNumberId: partNumber.id,
                    mass: typeof mass == 'number' ? mass : Number(mass[0]),
                    paintingArea:
                        typeof paintingArea == 'number'
                            ? paintingArea
                            : Number(paintingArea[0]),
                    finishAndUCCode: finishAndUCCode.id,
                    settingFinishId: this.settingFinish.id,
                    processes: processes.map(process => process.id),
                    settingProcessesId: this.settingProcesses.id,
                    ...paramsToSave,
                })
                this.$emit('addItem', newItem)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async updateItem() {
            try {
                this.loading = true
                const {
                    partNumber,
                    finishAndUCCode,
                    processes,
                    mass,
                    paintingArea,
                    ...paramsToUpdate
                } = this.itemDiff
                const updateItem = await API.updateWorkOrderItem({
                    workOrderId: this.workOrder.id,
                    itemId: this.item.id,
                    ...(partNumber ? { partNumberId: partNumber.id } : {}),
                    ...(finishAndUCCode
                        ? {
                              finishAndUCCode: finishAndUCCode.id,
                              settingFinishId: this.settingFinish.id,
                          }
                        : {}),
                    ...(mass
                        ? {
                              mass:
                                  typeof mass == 'number'
                                      ? mass
                                      : Number(mass[0]),
                          }
                        : {}),
                    ...(paintingArea
                        ? {
                              paintingArea:
                                  typeof paintingArea == 'number'
                                      ? paintingArea
                                      : Number(paintingArea[0]),
                          }
                        : {}),
                    ...(processes
                        ? {
                              processes: processes.map(process => process.id),
                              settingProcessesId: this.settingProcesses.id,
                          }
                        : {}),
                    ...paramsToUpdate,
                })
                this.$emit('replaceItem', updateItem)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
        restrictMassDecimals() {
            this.item.mass = this.item.mass.match(/^\d+\.?\d{0,2}/)
        },
        restrictPaintingAreaDecimals() {
            this.item.paintingArea = this.item.paintingArea.match(
                /^\d+\.?\d{0,2}/
            )
        },
    },
}
</script>

<style></style>
